import type { MouseEvent } from 'react'

interface ActionCardProps {
    textContent: string
    iconSrc?: string
    onClickAction?: (e: MouseEvent) => void
}

export const ActionCard = (props: ActionCardProps) => {
    const { textContent, iconSrc, onClickAction } = props

    return (
        <div
            className='action-card'
            onClick={onClickAction}
        >
            <span>{textContent}</span>
            <img
                src={iconSrc}
                className='action-card__icon'
                alt=''
            />
        </div>
    )
}
