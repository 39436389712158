import classnames from 'classnames'
import { useState, MouseEvent, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { ReactComponent as NoteIcon } from '../../../../assets/icons/note.svg'
import { useOrder } from '../../hooks'
import type { NewOrderProps } from '../../types'
import type { NewOrderPageProps } from '../../../../types'
import { Button } from '../../../../components/Button'
import { Modal } from '../../../../components'
import { useParams } from 'react-router-dom'
import { ReactComponent as Spinner } from '../../../../assets/icons/loader.svg'

type NoteProps = NewOrderPageProps &
    Pick<NewOrderProps, 'cartListData'> & {
        sendFunction?: (e: MouseEvent) => Promise<void>
    }

export const OrderNote = ({
    cartListData,
    isUrgent,
    sendFunction,
}: NoteProps) => {
    const { watch, register, getValues } = useFormContext()
    const { id } = useParams()
    const [noteOpen, setNoteOpen] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)

    const noteValue = watch('order-note')
    const noteMissing = noteValue === undefined || noteValue === ''

    const handleSendOrder = useOrder({ cartListData, isUrgent })

    const handleSaveClick = async (e: MouseEvent) => {
        e.preventDefault()
        setShowSpinner(true)
        await handleSendOrder(
            e,
            !!id ? undefined : 'nedokonceno',
            true,
            false,
            getValues('order-note'),
        )
        setNoteOpen(false)
        setShowSpinner(false)
    }

    return (
        <>
            <NoteIcon
                onClick={() => setNoteOpen(true)}
                className={classnames(
                    'hover:cursor-pointer [&>path]:stroke-gray-200 [&:hover>path]:stroke-blue-500 [&:hover>path]:transition-colors',
                    {
                        '[&>path]:!stroke-blue-500': !noteMissing,
                    },
                )}
            />
            <Modal
                isModalOpen={noteOpen}
                setIsModalOpen={setNoteOpen}
                className='grid w-[754px] p-8'
            >
                <span className='pb-4 text-center text-xl leading-normal text-blue-600'>
                    Poznámka pro objednávku
                </span>
                {!cartListData.length && (
                    <p className='mb-2 text-center text-red-600'>
                        Poznámku je možno zadat až po přidání produktů do košíku
                        !
                    </p>
                )}
                <textarea
                    {...register('order-note')}
                    disabled={!cartListData.length}
                    className='mb-4 h-36 resize-none rounded border-blue-500 disabled:bg-gray-100'
                ></textarea>
                <div className='text-right'>
                    <Button
                        onClick={sendFunction ? sendFunction : handleSaveClick}
                    >
                        <span className='flex items-center justify-between'>
                            uložit poznámku a zavřít
                            {showSpinner && (
                                <Spinner className='-mr-4 h-7 animate-spin stroke-white' />
                            )}
                        </span>
                    </Button>
                </div>
            </Modal>
        </>
    )
}
