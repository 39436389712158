import classnames from 'classnames'
import { HTMLAttributes, MouseEvent, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import shortLogo from '../assets/images/ez.svg'
import avatarIcon from '../assets/icons/avatar.svg'
import questionIcon from '../assets/icons/question-outline.svg'
import { useOpen } from '../hooks'
import { Modal } from './Modal'
import { getHelpText } from '../api'
import DOMPurify from 'dompurify'

export function Navigation({ className }: HTMLAttributes<HTMLElement>) {
    const [helpText, setHelpText] = useState<string | undefined>()
    let location = useLocation()
    const { isOpen, setIsOpen } = useOpen()

    const handleHelpModal = async (e: MouseEvent) => {
        try {
            e.preventDefault()
            const helpKey = location.pathname.split('/')[1]
            const { value } = await getHelpText(helpKey)
            setHelpText(value)
            setIsOpen(true)
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <nav className={classnames('navbar', className)}>
            <div className='grid gap-y-6'>
                <Link to='/home'>
                    <img
                        className='mx-auto w-[4.0625rem]'
                        src={shortLogo}
                        alt='e-žádanka zkrácené logo'
                    />
                </Link>
                <Link
                    className='navbar__link'
                    to='/account'
                >
                    <img
                        src={avatarIcon}
                        alt=''
                    />
                    Účet
                </Link>
            </div>
            <button
                className='navbar__link justify-self-end'
                onClick={handleHelpModal}
                type='button'
            >
                <img
                    src={questionIcon}
                    alt=''
                />
                Pomoc
            </button>
            {helpText && (
                <Modal
                    isModalOpen={isOpen}
                    setIsModalOpen={setIsOpen}
                    className='max-w-3xl px-12 pb-10 pt-12'
                >
                    <p
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(helpText),
                        }}
                    ></p>
                </Modal>
            )}
        </nav>
    )
}
