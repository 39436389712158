import { useEffect, useState } from 'react'
import { getProcessedOrders } from '../../api'
import { ProcessedOrder } from '../../types'
import { useOpen } from '../useOpen'

export const useShowProcessed = () => {
    const { isOpen, setIsOpen } = useOpen()
    const [processedOrders, setProcessedOrders] = useState<ProcessedOrder[]>()

    const getProcessed = async () => {
        const orders = await getProcessedOrders()
        setProcessedOrders(orders.data)
    }

    useEffect(() => {
        getProcessed()
        setIsOpen(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { showOrders: isOpen, processedOrders }
}
