export enum UserRole {
    zadavatel = 'zadavatel',
    schvalovatel = 'schvalovatel',
    kompletace = 'kompletace',
}

export interface ApiUser {
    email: string
    fullName: string
    organization: string
    roles: UserRole[]
}

export interface TokenRequestBody {
    UserName: string
    password: string
}

export interface Token {
    tokenCode: string
    expireTo: number
}
