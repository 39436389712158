import { useEffect, useState, MouseEvent, useContext } from 'react'
import { getExportLink, getOrderDetailList, updateOrder } from '../../api'
import { Button, Heading, OrderTable, Spinner } from '../../components'
import { DetailOrderItem, OrderState, PageProps } from '../../types'
import { useNavigate, useParams } from 'react-router-dom'
import { OrderDetailTable } from './components'
import { useOpen, usePageTitle } from '../../hooks'
import { AuthContext } from '../../context'
import { UserRole } from '../../api/user/types'
import { OrderBasicInfo } from '../../api/orders/types'
import classnames from 'classnames'
import { OrderNote } from '../NewOrder/components'
import { FormProvider, useForm } from 'react-hook-form'

export const OrderDetail = ({ pageTitle }: PageProps) => {
    const { id } = useParams()
    const { user } = useContext(AuthContext)

    pageTitle = `${pageTitle} | ${id}`
    usePageTitle({ pageTitle })

    const [orderItems, setOrderItems] = useState<DetailOrderItem[]>([])
    const [basicInfo, setBasicInfo] = useState<OrderBasicInfo>()
    const [newDrugSent, setNewDrugSent] = useState(false)

    const { isOpen, setIsOpen } = useOpen()

    const navigate = useNavigate()

    const isPharmacy = user?.roles[0] === 'kompletace'
    const infoHeaders = ['číslo', 'stav', isPharmacy ? 'organizace' : '']
    const disabledStates = ['dokonceno', 'zruseno', 'vracenoLekarnou']
    const hideActions = basicInfo && disabledStates.includes(basicInfo?.state)
    const disableDrugChange = isPharmacy && !hideActions

    const methods = useForm({ defaultValues: { 'order-note': '' } })
    const { setValue, getValues } = methods

    const handleSendOrder = async (
        e: MouseEvent,
        state: keyof typeof OrderState = 'prijatoNaLekarnu',
        note: string,
        sendNote?: boolean,
    ) => {
        if (id) {
            await updateOrder({
                numberOrder: id,
                state: state || (basicInfo && OrderState[basicInfo?.state]),
                note,
            }).then(() =>
                sendNote ? navigate(`/order-detail/${id}`) : navigate('/home'),
            )
        }
    }

    const handleExport = async () => {
        try {
            const exportUrl =
                id && (await getExportLink({ numberOrder: id, unsigned: true }))
            window.open(URL.createObjectURL(exportUrl))
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (!orderItems.length || newDrugSent) {
            const getOrderData = async () => {
                await getOrderDetailList(String(id)).then(
                    ({ data, companyName, state, note }) => {
                        setOrderItems(data)
                        setBasicInfo({ companyName, state, note })
                        setValue('order-note', note)
                    },
                )
            }

            getOrderData()
            return setNewDrugSent(false)
        }
        setIsOpen(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderItems, newDrugSent])

    return (
        <FormProvider {...methods}>
            <div className='pr-8'>
                <div className='mb-6 mr-2 flex items-center justify-between'>
                    <div className='mb-6 flex items-center gap-4'>
                        <Heading headingType='main'>Detail objednávky</Heading>
                        <OrderNote
                            cartListData={orderItems}
                            sendFunction={(e: MouseEvent) =>
                                handleSendOrder(
                                    e,
                                    basicInfo?.state,
                                    getValues('order-note'),
                                    true,
                                )
                            }
                        />
                    </div>
                    {user?.roles[0] === UserRole.kompletace && !hideActions && (
                        <div className='flex gap-2'>
                            <Button
                                buttontype='secondary'
                                size='small'
                                onClick={(e) =>
                                    handleSendOrder(
                                        e,
                                        'vracenoLekarnou',
                                        getValues('order-note'),
                                    )
                                }
                            >
                                vrátit objednávku
                            </Button>
                            <Button
                                onClick={(e) =>
                                    handleSendOrder(
                                        e,
                                        'dokonceno',
                                        getValues('order-note'),
                                    )
                                }
                                size='small'
                                sendIcon
                            >
                                zaslat objednávku
                            </Button>
                        </div>
                    )}
                </div>
                {isOpen ? (
                    <>
                        {basicInfo && (
                            <div
                                className={classnames(
                                    'mb-4 mr-2 items-end justify-between',
                                    {
                                        'grid grid-cols-[minmax(380px,_430px)_minmax(180px,_1fr)]':
                                            isPharmacy,
                                    },
                                )}
                            >
                                <OrderTable
                                    headers={infoHeaders}
                                    className='!w-auto border-spacing-y-0'
                                    autoColumns
                                    negativeMargin={false}
                                >
                                    <tr>
                                        <td>{id}</td>
                                        <td>{OrderState[basicInfo?.state]}</td>
                                        {isPharmacy && (
                                            <td>{basicInfo?.companyName}</td>
                                        )}
                                    </tr>
                                </OrderTable>
                                {disableDrugChange &&
                                    basicInfo.state !== 'vracenoLekarnou' && (
                                        <Button
                                            className='ml-auto w-fit'
                                            size='small'
                                            buttontype='secondary'
                                            onClick={() => handleExport()}
                                        >
                                            vytisknout aktuální
                                        </Button>
                                    )}
                            </div>
                        )}
                        <OrderDetailTable
                            orderNote={basicInfo?.note || ''}
                            setNewDrugSent={setNewDrugSent}
                            orderData={orderItems}
                            disableClick={disableDrugChange}
                        />
                    </>
                ) : (
                    <Spinner />
                )}
            </div>
        </FormProvider>
    )
}
