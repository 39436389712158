import plusIcon from '../../../assets/icons/plus-main.svg'
import archiveIcon from '../../../assets/icons/archive.svg'
import urgentIcon from '../../../assets/icons/urgent.svg'
import { ActionCard } from '../components'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../../../context'

export const ActionCards = () => {
    const navigate = useNavigate()
    const { user } = useContext(AuthContext)

    return (
        <div className='home-cards'>
            {user?.roles[0] !== 'kompletace' && (
                <ActionCard
                    textContent='Vytvořit novou objednávku'
                    iconSrc={plusIcon}
                    onClickAction={() => navigate('/new-order')}
                />
            )}
            <ActionCard
                textContent='Zobrazit všechny objednávky'
                iconSrc={archiveIcon}
                onClickAction={() => navigate('/all-orders')}
            />
            {user?.roles[0] === 'schvalovatel' && (
                <ActionCard
                    textContent='Vytvořit akutní objednávku'
                    iconSrc={urgentIcon}
                    onClickAction={() => navigate('/new-order-urgent')}
                />
            )}
        </div>
    )
}
