import classnames from 'classnames'
import type { HTMLAttributes } from 'react'

type HeadingType = 'main' | 'secondary'

interface HeadingProps {
    headingType: HeadingType
}

type Props = HTMLAttributes<HTMLHeadingElement> & HeadingProps

export const Heading = (props: Props) => {
    const { className, children, headingType, ...elementProps } = props

    return headingType === 'main' ? (
        <h1
            className={classnames(`heading heading--${headingType}`, className)}
            {...elementProps}
        >
            {children}
        </h1>
    ) : (
        <h2
            className={classnames(`heading heading--${headingType}`, className)}
            {...elementProps}
        >
            {children}
        </h2>
    )
}
