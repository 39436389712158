import type { PropsWithChildren } from 'react'
import { AuthContext } from '../../context'
import { AuthContextProviderProps } from '../../types'

export const AuthContextProvider = ({
    children,
    contextValue,
}: PropsWithChildren<AuthContextProviderProps>) => {
    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}
