import classnames from 'classnames'
import { useUIDSeed } from 'react-uid'
import { DrugObjType, DrugTableConfig, OrderState } from '../../types'
import { ReactComponent as AddOutlineIcon } from '../../assets/icons/plus-outline.svg'
import { ReactComponent as MinusOutlineIcon } from '../../assets/icons/minus-outline.svg'
import { ReactComponent as NoteIcon } from '../../assets/icons/note.svg'
import { formatDrugSpecialLabel } from '../../lib'
import { HTMLAttributes, useState, MouseEvent, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Button } from '../Button'
import { Modal } from '../Modal'
import { useOrder } from '../../pages/NewOrder/hooks'
import { getOrderDetailList, replaceDrugInOrder } from '../../api'
import { useParams } from 'react-router-dom'
import { ReplacementItem } from '../../types/orders'
import { ReactComponent as StateCrossIcon } from '../../assets/icons/cross.svg'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { DetailOrders } from '../../api/orders/types'
import { ReactComponent as Spinner } from '../../assets/icons/loader.svg'

interface DrugCellConfig extends HTMLAttributes<HTMLTableCellElement> {
    item: string | number | undefined
    disableButtons?: boolean
    isDetail?: boolean
    isUrgent?: boolean
}

type DrugCellProps = DrugCellConfig &
    Pick<
        DrugTableConfig<DrugObjType>,
        'drugObj' | 'handleAdd' | 'handleReduce' | 'cartListData'
    >

export const DrugTableCell = (props: DrugCellProps) => {
    const {
        item,
        drugObj,
        handleAdd,
        handleReduce,
        disableButtons,
        isDetail,
        isUrgent,
        cartListData = [],
        ...elementProps
    } = props
    const uid = useUIDSeed()
    const { id } = useParams()

    const { register, setValue, watch, getValues } = useFormContext()

    const [noteOpen, setNoteOpen] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const itemInCart = cartListData?.find((item) => item.id === drugObj?.id)
    const [currentNote, setCurrentNote] = useState('')
    const noteValue = watch('drug-note')
    const handleSendOrder = useOrder({ cartListData, isUrgent })
    const queryClient = useQueryClient()

    const { data, isLoading } = useQuery(
        ['order-items', id],
        (): Promise<DetailOrders> => getOrderDetailList(String(id)),
        { enabled: !!id },
    )

    const handleNoteOpen = (e: MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        isLoading && itemInCart && !!id
            ? setValue('drug-note', 'Loading...')
            : setValue('drug-note', '')

        queryClient
            .fetchQuery<DetailOrders>(['order-items', id])
            .then((data) =>
                setValue(
                    'drug-note',
                    data?.data.find((item) => item.id === drugObj?.id)?.note,
                ),
            )
        setNoteOpen(true)
    }

    const handleNoteClose = (e: MouseEvent) => {
        queryClient.fetchQuery<DetailOrders>(['order-items', id])
        setNoteOpen(false)
    }

    useEffect(() => {
        setCurrentNote(noteValue)
    }, [noteValue])

    const handleSaveNote = async (
        e: MouseEvent,
        orderState?: keyof typeof OrderState,
        navigateToSame?: boolean,
        orderNote = '',
    ) => {
        e.preventDefault()
        e.stopPropagation()
        if (drugObj?.id) {
            setShowSpinner(true)
            const noteItem: ReplacementItem = {
                drugID: drugObj?.id,
                amount: drugObj?.amount || 0,
                note: getValues('drug-note'),
                state: drugObj.state || 'ok',
                replacementForDrugID: '',
            }

            if (!!id) {
                await replaceDrugInOrder({
                    orderNumber: id,
                    items: [noteItem],
                })
                handleNoteClose(e)
                return setShowSpinner(false)
            }

            await handleSendOrder(
                e,
                orderState,
                navigateToSame,
                isDetail,
                getValues('order-note'),
            ).then(
                async (orderNumber) =>
                    await replaceDrugInOrder({
                        orderNumber: String(orderNumber),
                        items: [{ ...noteItem, note: currentNote }],
                    }),
            )

            handleNoteClose(e)
            setShowSpinner(false)
        }
    }

    const handleSpeacialText = () => {
        if (item === drugObj?.title || item === drugObj?.form) {
            return item && formatDrugSpecialLabel(item)
        }
        return item
    }

    const buttonAvailable =
        itemInCart || noteValue !== '' || noteValue !== undefined

    return (
        <td
            className={classnames('last:text-right', {
                'min-w-[210px] max-w-[215px] uppercase':
                    item === drugObj?.title,
                'min-w-[215px] max-w-[220px] uppercase': item === drugObj?.form,
                'min-w-[85px] max-w-[88px] uppercase': item === drugObj?.unit,
            })}
            key={uid(`${item}-${drugObj}`)}
            {...elementProps}
        >
            {item === drugObj?.id && drugObj?.state === 'emptywarehous' && (
                <StateCrossIcon className='absolute -left-8 [&>path]:stroke-red-400' />
            )}
            <span
                className={
                    item === drugObj?.amount
                        ? 'flex items-center justify-end'
                        : ''
                }
            >
                {item === drugObj?.amount ? (
                    <>
                        <NoteIcon
                            onClick={(e) => handleNoteOpen(e)}
                            className={classnames(
                                'mr-2 hover:cursor-pointer [&>path]:stroke-gray-200 [&:hover>path]:stroke-blue-500 [&:hover>path]:transition-colors',
                                {
                                    '[&>path]:!stroke-blue-500':
                                        !!data?.data.find(
                                            (item) => item.id === drugObj?.id,
                                        )?.note,
                                },
                            )}
                        />
                        <Modal
                            isModalOpen={noteOpen}
                            setIsModalOpen={setNoteOpen}
                            handleClose={handleNoteClose}
                            className='grid w-[754px] p-8'
                        >
                            <span className='pb-4 text-center text-xl leading-normal text-blue-600'>
                                Poznámka pro lék
                            </span>
                            {!itemInCart && (
                                <p className='mb-2 text-center text-red-600'>
                                    Poznámku je možno zadat až po přidání léku
                                    do košíku !
                                </p>
                            )}
                            <textarea
                                disabled={!itemInCart}
                                {...register('drug-note')}
                                onClick={(e) => e.stopPropagation()}
                                className={classnames(
                                    'mb-4 h-36 resize-none rounded border-blue-500',
                                    {
                                        'bg-gray-100': !itemInCart,
                                    },
                                )}
                            ></textarea>
                            <div className='text-right'>
                                <Button
                                    disabled={!buttonAvailable}
                                    onClick={(e: MouseEvent) =>
                                        handleSaveNote(
                                            e,
                                            !!id ? undefined : 'nedokonceno',
                                            true,
                                            getValues('order-note'),
                                        )
                                    }
                                >
                                    <span className='flex items-center justify-between'>
                                        uložit poznámku a zavřít
                                        {showSpinner && (
                                            <Spinner className='-mr-4 h-7 animate-spin stroke-white' />
                                        )}
                                    </span>
                                </Button>
                            </div>
                        </Modal>
                    </>
                ) : null}
                {item === drugObj?.amount && handleReduce && (
                    <MinusOutlineIcon
                        onClick={(e) => handleReduce(e, drugObj)}
                        className={classnames('h-[24px] w-[25px]', {
                            'cursor-pointer': !disableButtons,
                            'cursor-not-allowed': disableButtons,
                        })}
                    ></MinusOutlineIcon>
                )}
                <span className='w-[2ch] text-center'>
                    {handleSpeacialText()}
                </span>
                {item === drugObj?.amount && handleAdd && (
                    <AddOutlineIcon
                        onClick={(e) => handleAdd(e, drugObj)}
                        className={classnames('h-[24px] w-[25px]', {
                            'cursor-pointer': !disableButtons,
                            'cursor-not-allowed': disableButtons,
                        })}
                    ></AddOutlineIcon>
                )}
            </span>
        </td>
    )
}
