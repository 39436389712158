import axios from 'axios'
import { AxiosErrorResult } from '../errors'
import { getRestApiConfig } from './../config'
import { StaticProps } from './types'

const { baseUrl, apiConfig } = getRestApiConfig()

export const getHelpText = async (helpKey: string): Promise<StaticProps> => {
    try {
        const requestUrl = new URL(`api/v1/static-texts/${helpKey}`, baseUrl)
            .href
        const response = await axios.get(requestUrl, apiConfig)
        return response.data
    } catch (err: any) {
        throw AxiosErrorResult(err)
    }
}
