import classnames from 'classnames'
import { ButtonHTMLAttributes } from 'react'
import chevronRight from '../assets/icons/chevron-right.svg'
import { ButtonProps } from '../types'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps

export const Button = (props: Props) => {
    const {
        className,
        children,
        size = 'medium',
        buttontype = 'primary',
        sendIcon,
        type = 'button',
        ...elementProps
    } = props

    return (
        <button
            className={classnames(
                `button button--${size} button--${buttontype}`,
                { button__icon: sendIcon },
                className,
            )}
            type={type}
            {...elementProps}
        >
            <span className={sendIcon ? 'pr-1' : ''}>{children}</span>
            {sendIcon && (
                <img
                    src={chevronRight}
                    alt=''
                />
            )}
        </button>
    )
}
