import { ReactNode } from 'react'
import { Modal } from '../../../components'
import type { ModalStateProps } from '../../../types'

interface OrderActionConfig {
    modalheading?: string
    subheading?: string
    children?: ReactNode
}

type OrderActionProps = ModalStateProps & OrderActionConfig

export const OrderActionModal = (props: OrderActionProps) => {
    const { modalheading, subheading, children } = props

    return (
        <Modal
            {...props}
            className='px-[92px] pt-[72px] pb-[74px]'
        >
            <div className='grid gap-6 text-center'>
                <span className='text-xl leading-normal text-blue-600'>
                    {modalheading}
                </span>
                {subheading && (
                    <span className='text-md max-w-[520px] leading-normal text-blue-800'>
                        {subheading}
                    </span>
                )}
                <div className='flex justify-center gap-4'>{children}</div>
            </div>
        </Modal>
    )
}
