import { Dispatch, SetStateAction, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { OrderTable } from '../../../components'
import { TableOverflowContainer } from '../../../containers'
import { useOpen } from '../../../hooks'
import { DetailOrderItem } from '../../../types'
import { OrderDetailRow, OrderReplacementRow } from './OrderDetailRow'
import { ReplacementModal } from './ReplacementModal'

interface DetailTableProps {
    orderData: DetailOrderItem[]
    setNewDrugSent: Dispatch<SetStateAction<boolean>>
    disableClick?: boolean
    orderNote: string
}

export const OrderDetailTable = (props: DetailTableProps) => {
    const { orderData, setNewDrugSent, disableClick, orderNote } = props
    const { isOpen, setIsOpen } = useOpen(false)
    const { setValue } = useFormContext()
    const [drugToReplace, setDrugToReplace] = useState<DetailOrderItem>()

    const handleDrugToReplace = (drugObj: DetailOrderItem) => {
        if (!!drugObj) {
            setDrugToReplace(drugObj)
            setValue('drug-state', drugObj.state)
            setIsOpen(true)
        }
    }

    return (
        <TableOverflowContainer
            className='mb-8 -mr-[32px] max-h-[50vh]'
            negativeMargin={false}
        >
            <OrderTable
                autoColumns
                className='pr-8'
                headers={['sukl', 'název', 'forma', 'MJ', 'množství']}
                lastRight
                negativeMargin={false}
            >
                {orderData.map((order: DetailOrderItem) => (
                    <>
                        {!!order.replacementFor ? (
                            <OrderReplacementRow
                                orderNote={orderNote}
                                orderData={orderData}
                                setNewDrugSent={setNewDrugSent}
                                disableClick={disableClick}
                                handleDrugToReplace={() =>
                                    disableClick && handleDrugToReplace(order)
                                }
                                order={order}
                            />
                        ) : (
                            <OrderDetailRow
                                orderNote={orderNote}
                                orderData={orderData}
                                handleDrugToReplace={() =>
                                    disableClick && handleDrugToReplace(order)
                                }
                                disableClick={disableClick}
                                order={order}
                            />
                        )}
                    </>
                ))}
            </OrderTable>
            {drugToReplace && (
                <ReplacementModal
                    drugToReplace={drugToReplace}
                    isModalOpen={isOpen}
                    setIsModalOpen={setIsOpen}
                    setNewDrugSent={setNewDrugSent}
                    orderNote={orderNote}
                />
            )}
        </TableOverflowContainer>
    )
}
