import classnames from 'classnames'
import { useEffect, useState } from 'react'
import { Heading } from '../../components'
import { DrugSearch } from '../../components/DrugSearch'
import { useOpen, usePageTitle } from '../../hooks'
import { CartWrapper, OrderNote } from './components'
import { OpenContent } from './containers'
import type { NewOrderPageProps, DrugOrderItem, PageProps } from '../../types'
import { useParams } from 'react-router-dom'
import { getOrderDetailList } from '../../api'
import { FormProvider, useForm } from 'react-hook-form'

type Props = NewOrderPageProps & PageProps

export const NewOrder = ({ isUrgent, pageTitle }: Props) => {
    const { id, duplicatedId } = useParams()

    if (!!id) pageTitle = `${pageTitle} | ${id}`
    usePageTitle({ pageTitle })

    const { isOpen, setIsOpen } = useOpen()
    const cancelModalStates = useOpen()
    const sendModalStates = useOpen()

    const [searchList, setSearchList] = useState<DrugOrderItem[]>([])
    const [cartListData, setCartListData] = useState<DrugOrderItem[]>([])

    const methods = useForm({ defaultValues: { 'order-note': '' } })
    const { setValue, getValues } = methods

    const cancelState = {
        isModalOpen: cancelModalStates.isOpen,
        setIsModalOpen: cancelModalStates.setIsOpen,
    }

    const sendState = {
        isModalOpen: sendModalStates.isOpen,
        setIsModalOpen: sendModalStates.setIsOpen,
    }

    useEffect(() => {
        const hasData = !!id || !!duplicatedId
        const getSavedCart = async () => {
            const savedList = await getOrderDetailList(
                String(id || duplicatedId),
            )
            setCartListData(savedList.data)
            setValue('order-note', savedList.note)
        }

        if (!!hasData) {
            getSavedCart()
            return
        }
    }, [id, duplicatedId])

    return (
        <FormProvider {...methods}>
            <form className='mr-[32px]'>
                <div className='mb-6 flex items-center gap-4'>
                    <Heading headingType='main'>Zadání objednávky</Heading>
                    <OrderNote
                        cartListData={cartListData}
                        isUrgent={isUrgent}
                    />
                </div>
                <div
                    className={classnames('new-order-content', {
                        'gap-y-8': isOpen,
                        'gap-y-3': !isOpen,
                        'content--open': isOpen,
                        'content--closed': !isOpen,
                    })}
                >
                    <DrugSearch
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        setSearchList={setSearchList}
                        withArea
                    />
                    <CartWrapper
                        isUrgent={isUrgent}
                        cartListData={cartListData}
                        setCartListData={setCartListData}
                        listData={cartListData}
                        cancelState={cancelState}
                        sendState={sendState}
                    />
                    {!!cartListData.length && !isOpen && (
                        <div
                            className='mb-5 flex flex-col gap-y-4 rounded bg-blue-50 p-4 text-blue-800'
                            style={{ gridArea: 'cart-detail' }}
                        >
                            <div className='flex justify-between'>
                                <span>Název</span>
                                <span>množství</span>
                            </div>
                            {cartListData?.slice(0, 10).map((item) => (
                                <div className='flex justify-between'>
                                    <span className='max-w-[80%] truncate'>
                                        {item.title}
                                    </span>
                                    <span>{item.amount}</span>
                                </div>
                            ))}
                        </div>
                    )}
                    <OpenContent
                        orderNote={getValues('order-note')}
                        isUrgent={isUrgent}
                        cartListData={cartListData}
                        setCartListData={setCartListData}
                        listData={searchList}
                        isContentOpen={isOpen}
                        cancelState={cancelState}
                        sendState={sendState}
                    />
                </div>
            </form>
        </FormProvider>
    )
}
