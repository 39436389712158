import classnames from 'classnames'
import { TableHTMLAttributes } from 'react'
import { useUIDSeed } from 'react-uid'
import { OrderTableConfig } from '../../types'

type Props = TableHTMLAttributes<HTMLTableElement> & OrderTableConfig

export const OrderTable = (props: Props) => {
    const {
        headers,
        children,
        className,
        autoColumns,
        negativeMargin = true,
        lastRight,
        ...elementProps
    } = props

    const uid = useUIDSeed()

    return (
        <table
            className={classnames(
                'order-table',
                { 'auto-columns': autoColumns, '-mx-2': negativeMargin },
                className,
            )}
            {...elementProps}
        >
            <thead className='sticky top-0 z-10 bg-white'>
                <tr>
                    {headers?.map((item: string) => (
                        <th
                            className={
                                lastRight ? 'last-of-type:text-right' : ''
                            }
                            key={uid(item)}
                        >
                            {item}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </table>
    )
}
