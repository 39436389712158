import { HTMLAttributes } from 'react'

interface TableOverflowConfig {
    negativeMargin?: boolean
}

type TableOverflowProps = TableOverflowConfig & HTMLAttributes<HTMLDivElement>

export const TableOverflowContainer = ({
    className,
    children,
    negativeMargin = true,
    ...elementProps
}: TableOverflowProps) => (
    <div
        className={`${
            !!negativeMargin ? '-ml-2' : ''
        } overflow-x-visible overflow-y-scroll scrollbar-thin scrollbar-track-blue-100/80 scrollbar-thumb-blue-300 scrollbar-track-rounded scrollbar-thumb-rounded hover:scrollbar-thumb-blue-400 ${className}`}
        {...elementProps}
    >
        {children}
    </div>
)
