import { Button, Input } from '../../components'
import fullLogo from '../../assets/images/ezadanka-full.svg'
import { ChangeEvent, useContext, useEffect, useRef } from 'react'
import { useSubmit } from './hooks'
import { AuthContext } from '../../context'
import { useNavigate } from 'react-router-dom'
import { PageProps } from '../../types'
import { usePageTitle } from '../../hooks'

type InputChangeEvent = ChangeEvent<HTMLInputElement>

export const Login = ({ pageTitle }: PageProps) => {
    usePageTitle({ pageTitle })
    const userNameRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)
    const { isAuthenticated } = useContext(AuthContext)
    const navigate = useNavigate()

    const {
        userName,
        password,
        setUserName,
        setPassword,
        userNameError,
        passwordError,
        handleSubmit,
        signInError,
    } = useSubmit()

    useEffect(() => {
        isAuthenticated && navigate('/home')
    }, [isAuthenticated, navigate])

    return (
        <div className='flex max-w-[424px] flex-col'>
            <img
                src={fullLogo}
                alt='celé logo e-žádanky'
            />
            <div className='mb-8'>
                <h1 className='mb-2 text-4xl leading-normal text-blue-800'>
                    Přihlašte se
                </h1>
                <p className='text-sm leading-normal text-blue-700'>
                    Vítejte zpátky! Prosím vypište své údaje.
                </p>
            </div>
            <form
                ref={formRef}
                onSubmit={handleSubmit}
                className='flex flex-col gap-4'
                action=''
                noValidate
            >
                <Input
                    ref={userNameRef}
                    placeholder='mail@mail.cz'
                    type='email'
                    onChange={({ target }: InputChangeEvent) =>
                        setUserName(target.value)
                    }
                    value={userName}
                />
                {userNameError && (
                    <span className='rounded bg-red-50 p-2 text-sm text-blue-800'>
                        Vaše uživatelské jméno nemá správný tvar
                    </span>
                )}
                <Input
                    ref={passwordRef}
                    placeholder='heslo'
                    type='password'
                    onChange={({ target }: InputChangeEvent) =>
                        setPassword(target.value)
                    }
                    value={password}
                />
                {passwordError && (
                    <span className='rounded bg-red-50 p-2 text-sm text-blue-800'>
                        Vaše uživatelské heslo nemá správný tvar. Musí obsahovat
                        8-15 znaků, alespoň 1 malé písmeno, velké písmeno,
                        číslici a speciální znak
                    </span>
                )}
                {signInError && (
                    <span className='rounded bg-red-50 p-2 text-sm text-blue-800'>
                        Nedokázali jsme najít uživatele s tímto přihlašovacím
                        jménem nebo heslem. Zkontrolujte prosím své přihlašovací
                        údaje.
                    </span>
                )}
                {/* TODO: Add when forgotten password is implemented on BE */}
                {/* <button
                    onClick={() => navigate('/forgotten-password')}
                    className='ml-auto text-xs leading-normal text-blue-600 hover:underline'
                    type='button'
                >
                    zapomenuté heslo
                </button> */}
                <Button type='submit'>Příhlásit se</Button>
            </form>
        </div>
    )
}
