import {
    useState,
    MouseEvent,
    Dispatch,
    SetStateAction,
    useEffect,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { replaceDrugInOrder } from '../../../api'
import { Button, DrugSearch, DrugTable, Modal } from '../../../components'
import { TableOverflowContainer } from '../../../containers'
import { useOpen } from '../../../hooks'
import { DetailOrderItem, DrugOrderItem, ModalStateProps } from '../../../types'
import { ReplacementItem } from '../../../types/orders'
import { useDrugReplace } from '../hooks'

interface ReplacementConfig {
    drugToReplace: DetailOrderItem
    setNewDrugSent?: Dispatch<SetStateAction<boolean>>
    orderNote: string
}

type ReplacementModalProps = ModalStateProps & ReplacementConfig

export const ReplacementModal = (props: ReplacementModalProps) => {
    const { drugToReplace, setNewDrugSent, setIsModalOpen } = props
    const [searchList, setSearchList] = useState<DrugOrderItem[]>([])
    const [newDrug, setNewDrug] = useState<DrugOrderItem>()
    const { isOpen, setIsOpen } = useOpen()
    const { register, watch, setValue } = useFormContext()

    const drugState = watch('drug-state')

    const { id } = useParams()

    const { handleDrugReplace, handleAddDrug, handleReduceDrug } =
        useDrugReplace(newDrug, setNewDrug)

    const handleChangeButton = async (e: MouseEvent) => {
        newDrug &&
            (await handleDrugReplace(
                e,
                id,
                drugToReplace,
                newDrug,
                setNewDrug,
                setNewDrugSent,
            ))
        setIsModalOpen(false)
    }

    const handleStateChange = async (state: 'ok' | 'emptywarehous') => {
        if (id) {
            const item: ReplacementItem = {
                drugID: drugToReplace.id,
                amount: drugToReplace.amount,
                note: drugToReplace.note,
                state: drugToReplace.state,
            }

            console.log(item)
            await replaceDrugInOrder({
                orderNumber: id,
                items: [{ ...item, state: state || 'ok' }],
            }).then(() => setNewDrugSent && setNewDrugSent(true))
        }
    }

    useEffect(() => {
        if (drugState !== drugToReplace.state) {
            handleStateChange(drugState)
            setIsModalOpen(false)
        }
    }, [drugState])

    return drugToReplace ? (
        <Modal
            className='grid w-[906px] gap-5 p-8'
            {...props}
        >
            <span className='text-center text-xl leading-normal text-blue-600'>
                Změna léku
            </span>
            <DrugTable
                handleDrugAmount={() => drugToReplace.amount}
                drugObj={drugToReplace}
                className='px-5'
            />
            <div className='flex items-center px-5 text-blue-600'>
                <p>stav léku:</p>
                <select
                    {...register('drug-state')}
                    className='ml-2 w-36 cursor-pointer border-none p-0 focus:ring-0'
                >
                    <option value='ok'>k dispozici</option>
                    <option value='emptywarehous'>není na skladě</option>
                </select>
            </div>
            <DrugSearch
                className='mx-5'
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setSearchList={setSearchList}
            />
            {!!searchList.length && (
                <TableOverflowContainer className='ml-0 max-h-[50vh]'>
                    <DrugTable
                        className='px-5'
                        drugList={searchList}
                        handleAdd={(e, drugObj) => handleAddDrug(e, drugObj)}
                        handleReduce={(e, drugObj) =>
                            handleReduceDrug(e, drugObj)
                        }
                        handleDrugAmount={(drugObj) =>
                            newDrug?.id === drugObj?.id ? newDrug?.amount : 0
                        }
                        hideHeaders
                        disableRow={(drugObj) =>
                            newDrug ? newDrug?.id !== drugObj?.id : false
                        }
                    />
                </TableOverflowContainer>
            )}
            <div className='px-5 text-right'>
                <Button
                    disabled={!!!newDrug}
                    onClick={handleChangeButton}
                    size='small'
                >
                    změnit lék
                </Button>
            </div>
        </Modal>
    ) : null
}
