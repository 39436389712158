import { useOpen } from './../../../hooks/useOpen'
import { useAuth } from './../../../hooks/useAuth'
/* eslint-disable react-hooks/exhaustive-deps */
import { FormEvent, useEffect, useState } from 'react'

export const useSubmit = () => {
    const [userName, setUserName] = useState('')
    const [userNameError, setUserNameError] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState(false)
    // eslint-disable-next-line no-useless-escape
    const userNameValidation = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const passwordValidation =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/

    const { handleSignIn, signInError } = useAuth()

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        if (
            !userNameValidation.test(userName)
            // || !passwordValidation.test(password)
        ) {
            if (!userNameValidation.test(userName)) setUserNameError(true)
            // if (!passwordValidation.test(password)) setPasswordError(true)
            return
        }
        await handleSignIn({ UserName: userName, password })
    }

    useEffect(() => {
        if (userNameValidation.test(userName)) setUserNameError(false)
        // if (passwordValidation.test(password)) setPasswordError(false)
    }, [userName, password])

    return {
        userName,
        setUserName,
        password,
        setPassword,
        userNameError,
        passwordError,
        handleSubmit,
        signInError,
    }
}
