import { AxiosError } from 'axios'

export type ProblemDetails = {
    status: number
    title: string
    detail?: string
    instance?: string
}

export const AxiosErrorResult = (
    error: AxiosError<ProblemDetails>,
): ProblemDetails => {
    const uknown: ProblemDetails = {
        status: 500,
        title: 'Internal server error',
        instance: 'ERRORCODE_INTERNALSERVERERROR',
    }
    return error.response?.data ?? uknown
}
