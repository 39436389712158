import { UserRole } from '../api/user/types'
import { OrderState } from '../types'
import { handleInteractivity } from '../utils/access'

// whole class has to be written
// tailwindcss purge excludes classes when interpolated with small portions
// ex. bg-${color}-200

const colorTypes = {
    green: 'bg-green-200/60 transition-colors hover:bg-green-200',
    red: 'bg-red-200/60 transition-colors hover:bg-red-200',
    gray: 'bg-gray-200/60 transition-colors hover:bg-gray-200',
}

export const setInteractivity = (
    orderState: keyof typeof OrderState,
    userRole: UserRole,
): string => {
    if (userRole) {
        const interactivity = handleInteractivity(orderState, userRole)
        if (interactivity === true) {
            return colorTypes.red
        } else if (interactivity === false) return colorTypes.green
        else return colorTypes.gray
    }
    return ''
}
