import { TableHTMLAttributes } from 'react'
import { useUIDSeed } from 'react-uid'
import { DrugObjType, DrugTableConfig } from '../../types'
import { DrugTableRow } from './DrugTableRow'
import { OrderTable } from './OrderTable'

export const DrugTable = (
    props: DrugTableConfig<DrugObjType> & TableHTMLAttributes<HTMLTableElement>,
) => {
    const {
        drugList,
        cartListData,
        handleAdd,
        handleReduce,
        hideHeaders,
        drugObj,
        handleDrugAmount,
        isUrgent,
        disableRow,
        negativeMargin,
        ...elementProps
    } = props
    const uid = useUIDSeed()

    return (
        <OrderTable
            autoColumns
            negativeMargin={negativeMargin}
            headers={
                hideHeaders ? [] : ['sukl', 'název', 'forma', 'MJ', 'množství']
            }
            lastRight
            {...elementProps}
        >
            {drugList?.map((drugObj: DrugObjType) => (
                <DrugTableRow
                    cartListData={cartListData}
                    key={uid(drugObj)}
                    drugObj={drugObj}
                    handleAdd={handleAdd}
                    handleDrugAmount={handleDrugAmount}
                    handleReduce={handleReduce}
                    disableRow={disableRow}
                    isUrgent={isUrgent}
                />
            ))}
            {drugObj && (
                <DrugTableRow
                    cartListData={cartListData}
                    key={uid(drugObj)}
                    drugObj={drugObj}
                    handleAdd={handleAdd}
                    handleDrugAmount={handleDrugAmount}
                    handleReduce={handleReduce}
                    disableRow={disableRow}
                    isUrgent={isUrgent}
                />
            )}
        </OrderTable>
    )
}
