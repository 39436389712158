import type { ReplaceDrugProps } from './../../types'
import axios from 'axios'
import { getRestApiConfig } from './../config'
import type {
    ProcessedOrders,
    DetailOrders,
    DrugsSearchList,
    NewOrderItemsResponse,
    ExportProps,
} from './types'
import type { NewOrderItems } from '../../pages/NewOrder/types'
import type { ProcessedOrder } from '../../types'
import { AxiosErrorResult } from '../errors'

const { baseUrl, apiConfig } = getRestApiConfig()

export const getProcessedOrders = async (): Promise<ProcessedOrders> => {
    const requestUrl = new URL('api/v1/orders', baseUrl).href
    const response = await axios.get<ProcessedOrders>(requestUrl, apiConfig)
    return response.data
}

export const getOrderDetailList = async (
    orderNumber: string,
): Promise<DetailOrders> => {
    try {
        const requestUrl = new URL(`api/v1/order-items/${orderNumber}`, baseUrl)
            .href
        const response = await axios.get<DetailOrders>(requestUrl, apiConfig)
        return response.data
    } catch (e: any) {
        throw AxiosErrorResult(e)
    }
}

export const replaceDrugInOrder = async ({
    orderNumber,
    items,
}: ReplaceDrugProps): Promise<DetailOrders> => {
    try {
        const requestUrl = new URL(`api/v1/order-items`, baseUrl).href
        const requestBody = {
            orderNumber,
            items,
        }
        const response = await axios.put<DetailOrders>(
            requestUrl,
            requestBody,
            apiConfig,
        )
        return response.data
    } catch (e: any) {
        throw AxiosErrorResult(e)
    }
}

export const getDrugsSearchList = async (
    searchValue: string,
): Promise<DrugsSearchList> => {
    try {
        const requestUrl = new URL(
            `api/v1/drugs?foundTextCt=${searchValue}`,
            baseUrl,
        ).href
        const response = await axios.get<DrugsSearchList>(requestUrl, apiConfig)
        return response.data
    } catch (e: any) {
        throw AxiosErrorResult(e)
    }
}

export const createNewOrder = async (
    isUrgent?: boolean,
): Promise<ProcessedOrder> => {
    try {
        const requestUrl = new URL('api/v1/orders', baseUrl).href
        const requestBody = {
            isUrgent: isUrgent || false,
        }
        const response = await axios.post<ProcessedOrder>(
            requestUrl,
            requestBody,
            apiConfig,
        )
        return response.data
    } catch (e: any) {
        throw AxiosErrorResult(e)
    }
}

export const sendDrugsToOrder = async ({
    orderNumber,
    items,
    note,
}: NewOrderItems): Promise<NewOrderItemsResponse> => {
    try {
        const requestBody = {
            orderNumber,
            items,
            note,
        }

        const requestUrl = new URL(`api/v1/order-items`, baseUrl).href

        const response = await axios.post<NewOrderItemsResponse>(
            requestUrl,
            requestBody,
            apiConfig,
        )
        return response.data
    } catch (e: any) {
        throw AxiosErrorResult(e)
    }
}

export const updateOrder = async ({
    numberOrder,
    isUrgent = false,
    state,
    note,
}: Pick<
    ProcessedOrder,
    'numberOrder' | 'state' | 'isUrgent' | 'note'
>): Promise<ProcessedOrder> => {
    try {
        const requestUrl = new URL('api/v1/orders', baseUrl).href
        const requestBody = {
            numberOrder: numberOrder,
            isUrgent: isUrgent || false,
            state: state,
            note,
        }

        const response = await axios.put<ProcessedOrder>(
            requestUrl,
            requestBody,
            apiConfig,
        )
        return response.data
    } catch (e: any) {
        throw AxiosErrorResult(e)
    }
}

export const getExportLink = async ({ numberOrder, unsigned }: ExportProps) => {
    try {
        const { apiConfig: apiPdfConfig } = getRestApiConfig(undefined, true)
        const requestUrl = new URL(
            `api/v1/print-orders/${numberOrder}${
                unsigned ? '?unsigned=true' : ''
            }`,
            baseUrl,
        ).href
        const response = await axios.get(requestUrl, {
            ...apiPdfConfig,
            responseType: 'blob',
        })
        return response.data
    } catch (e: any) {
        throw AxiosErrorResult(e)
    }
}
