import { useQuery } from '@tanstack/react-query'
import debounce from 'lodash.debounce'
import {
    ChangeEvent,
    FormEvent,
    useEffect,
    useState,
    MouseEvent,
    RefObject,
} from 'react'
import { getDrugsSearchList } from '../api'
import { DrugSearchProps } from '../types'

interface UseSearchConfig {
    searchInput: RefObject<HTMLInputElement>
}

type UseSearchProps = Pick<DrugSearchProps, 'setIsOpen' | 'setSearchList'> &
    UseSearchConfig

export const useSearch = ({
    setSearchList,
    setIsOpen,
    searchInput,
}: UseSearchProps) => {
    const [searchTerm, setSearchTerm] = useState('')

    const delaySearchTerm = debounce((value: string) => {
        setSearchTerm(value)
    }, 500)

    const handleSearchValue = async (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        delaySearchTerm(e.target.value)
    }

    const handleStopSearch = (e: MouseEvent) => {
        e.preventDefault()
        if (searchInput.current) searchInput.current.value = ''
        setIsOpen(false)
        setSearchTerm('')
        setSearchList([])
    }

    const handleSubmit = async (e: FormEvent) => e.preventDefault()

    const { isLoading } = useQuery(
        ['drugs', searchTerm],
        async () => await getDrugsSearchList(searchTerm),
        {
            enabled: !!searchTerm.length,
            onSuccess: ({ data }) => {
                const newDataArr = [...data].map((item) => ({
                    ...item,
                    amount: 0,
                }))
                setSearchList(newDataArr)
            },
        },
    )

    useEffect(() => {
        if (searchInput.current?.value === '') {
            setSearchList([])
        }
    }, [searchInput, searchTerm, setSearchList])

    useEffect(() => {
        return () => {
            delaySearchTerm.cancel()
        }
    }, [delaySearchTerm])

    return {
        handleSearchValue,
        handleStopSearch,
        handleSubmit,
        searchTerm,
        isPending: isLoading,
    }
}
