import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
    getOrderDetailList,
    replaceDrugInOrder,
} from './../../../api/orders/services'
import { NewOrderPageProps } from './../../../types/pages/NewOrder'
import { useNavigate } from 'react-router-dom'
import { updateOrder, createNewOrder } from './../../../api'
import { MouseEvent, useEffect, useState } from 'react'
import { NewOrderProps } from '../types'
import { DrugOrderItem, OrderState, ProcessedOrder } from '../../../types'
import { useParams } from 'react-router-dom'

type UseOrderProps = Pick<NewOrderProps, 'cartListData'> & NewOrderPageProps

export const useOrder = ({ cartListData, isUrgent }: UseOrderProps) => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [pastItems, setPastItems] = useState<DrugOrderItem[]>()

    const formatToUpdate = (arrayToFormat: DrugOrderItem[]) => {
        return arrayToFormat.map((item) => ({
            drugID: item.id,
            note: item.note || '',
            state: item.state || 'ok',
            amount: Number(item.amount),
        }))
    }

    let orderNumber: string

    const orderItems = formatToUpdate(cartListData)

    // Get difference between past items and current cart items
    const differenceItems = pastItems?.filter(
        ({ id }) => !cartListData?.some(({ id: cartId }) => id === cartId),
    )

    const differenceItemsFormated =
        differenceItems && formatToUpdate(differenceItems)

    const orderDetailQuery = useQuery(
        ['order-items', id],
        () => getOrderDetailList(String(id)),
        { enabled: !!id },
    )

    useEffect(() => {
        setPastItems(orderDetailQuery.data?.data)
    }, [id, orderDetailQuery.data?.data])

    // Set difference array amount to 0
    // required for deleting object in order
    useEffect(() => {
        differenceItemsFormated?.forEach((element) => {
            element.amount = 0
        })
    }, [cartListData, differenceItemsFormated])

    const cancelOrderUpdateState = ['zruseno', 'dokonceno']

    const queryClient = useQueryClient()

    const handleSendOrder = async (
        e: MouseEvent,
        orderState?: keyof typeof OrderState,
        navigateToSame?: boolean,
        detail?: boolean,
        orderNote: string = '',
    ): Promise<string | undefined> => {
        e.preventDefault()
        try {
            // If there's no data in cart, skip implementation
            if (!!cartListData.length) {
                // When order already active use the current order id
                if (!id) {
                    await createNewOrder(isUrgent).then(
                        (data) => (orderNumber = data.numberOrder),
                    )
                } else {
                    orderNumber = id
                }

                // If there's no state passed use current order state
                if (!!!orderState) {
                    orderState = orderDetailQuery.data?.state || 'nedokonceno'
                }

                if (
                    !cancelOrderUpdateState.includes(orderState) &&
                    orderItems
                ) {
                    await replaceDrugInOrder({
                        orderNumber,
                        items: !!differenceItemsFormated?.length
                            ? orderItems.concat(differenceItemsFormated)
                            : orderItems,
                    })
                }

                // When urgent order created set state to "finished"
                await updateOrder({
                    numberOrder: orderNumber,
                    isUrgent: isUrgent || false,
                    state: isUrgent ? 'dokonceno' : orderState,
                    note: orderNote,
                }).then(({ state, numberOrder }) => {
                    navigateToSame
                        ? navigate(
                              `/${
                                  detail ? 'order-detail' : 'new-order'
                              }/${numberOrder}`,
                          )
                        : isUrgent || state === 'zruseno'
                        ? navigate('/all-orders')
                        : navigate('/home')
                    queryClient.invalidateQueries(['order-items'])
                })

                return orderNumber
            }
        } catch (err) {
            console.log(err)
        }
    }

    return handleSendOrder
}
