import { useEffect, useState } from 'react'
import type { ApiUser, TokenRequestBody } from './../api/user/types'
import { getMe, getToken } from './../api/user/services'
import { useOpen } from './useOpen'

export const useAuth = () => {
    const [token, setToken] = useState(
        localStorage.getItem('token') || undefined,
    )
    const [user, setUser] = useState<ApiUser | undefined>()
    const { isOpen: signInError, setIsOpen: setSignInError } = useOpen(false)

    const isAuthenticated = !!token && !!user

    const handleSignIn = async (dataProps: TokenRequestBody) => {
        localStorage.removeItem('token')
        await getToken(dataProps)
            .then((response) => {
                setToken(response.tokenCode)
                localStorage.setItem('token', response.tokenCode)
                window.location.replace('/home')
                setSignInError(false)
            })
            .catch((error) => {
                setSignInError(true)
                console.error(error)
            })
    }

    const handleSignOut = () => {
        localStorage.removeItem('token')
        setToken(undefined)
        window.location.replace('/login')
    }

    useEffect(() => {
        if (!user && !!token) getMe(token).then((data) => setUser(data))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    return {
        handleSignIn,
        handleSignOut,
        signInError,
        contextValue: {
            isAuthenticated,
            token,
            user,
        },
    }
}
