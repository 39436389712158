import { UserRole } from '../api/user/types'
import { OrderState } from '../types'

export const handleInteractivity = (
    orderState: keyof typeof OrderState,
    userRole: keyof typeof UserRole,
) => {
    switch (orderState) {
        case 'predanoNaLekarnu':
        case 'prijatoNaLekarnu':
            if (userRole === 'kompletace') {
                return true
            } else return false
        case 'predanoKeKontrole':
        case 'vracenoLekarnou':
            if (userRole === 'schvalovatel') {
                return true
            } else return false
        default:
            return undefined
    }
}

export const handleAccess = (
    orderState: keyof typeof OrderState,
    userRole: keyof typeof UserRole,
) => {
    switch (orderState) {
        case 'nedokonceno':
            if (userRole !== 'kompletace') {
                return true
            } else return false
        case 'predanoKeKontrole':
        case 'vracenoLekarnou':
            if (userRole === 'schvalovatel') {
                return true
            } else return false
        case 'dokonceno':
        case 'zruseno':
        case 'predanoNaLekarnu':
        case 'prijatoNaLekarnu':
        default:
            return false
    }
}
