import { NewOrderTable, OrderActions } from '../components'
import type { OpenContentProps } from '../types'

export const OpenContent = (props: OpenContentProps) => {
    const {
        isContentOpen,
        cancelState,
        sendState,
        cartListData,
        listData,
        setCartListData,
        isUrgent,
        orderNote,
    } = props

    const handleCancelClick = () => cancelState.setIsModalOpen(true)
    const handleSendClick = () => sendState.setIsModalOpen(true)

    return isContentOpen ? (
        <div className='orders grid pb-10'>
            {!!listData.length && (
                <NewOrderTable
                    cartListData={cartListData}
                    setCartListData={setCartListData}
                    listData={listData}
                    inOpenContent
                />
            )}
            <div className='ml-auto flex gap-4'>
                <OrderActions
                    orderNote={orderNote}
                    isUrgent={isUrgent}
                    cartListData={cartListData}
                    setCartListData={setCartListData}
                    listData={listData}
                    cancelButtonClick={handleCancelClick}
                    sendButtonClick={handleSendClick}
                    cancelState={cancelState}
                    sendState={sendState}
                />
            </div>
        </div>
    ) : (
        <div className='orders ml-auto flex gap-4'>
            <OrderActions
                orderNote={orderNote}
                isUrgent={isUrgent}
                cartListData={cartListData}
                setCartListData={setCartListData}
                listData={listData}
                cancelButtonClick={handleCancelClick}
                sendButtonClick={handleSendClick}
                cancelState={cancelState}
                sendState={sendState}
                showMainButtons={!!cartListData.length || false}
            />
        </div>
    )
}
