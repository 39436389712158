import moment from 'moment'
import { Outlet } from 'react-router-dom'
import { Footer, Navigation, UserOptions } from '../components'

moment.updateLocale('cz', {
    weekdaysMin: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
})

export const WithNavigation = ({ isWider }: { isWider?: boolean }) => {
    return (
        <div className='container mt-[72px]'>
            <div className='content-navbar'>
                <Navigation />
                <div className='content-navbar__info'>
                    <div className='flex flex-col'>
                        <span className='pb-1 text-2xl text-blue-800'>
                            Dobrý den
                        </span>
                        <span className='text-blue-600'>
                            {moment().locale('cz').format('dd D.M.')}
                        </span>
                    </div>
                    <UserOptions />
                </div>
                <div
                    className={`content-navbar__main ${
                        !!isWider ? 'pl-8' : ''
                    }`}
                >
                    <Outlet />
                </div>
            </div>
            <Footer />
        </div>
    )
}
