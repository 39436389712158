import classnames from 'classnames'
import { InputHTMLAttributes, forwardRef } from 'react'

interface InputConfig {
    withBorder?: boolean
}

type InputProps = InputHTMLAttributes<HTMLInputElement> & InputConfig

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const { withBorder = true, type, className } = props

    return (
        <input
            ref={ref}
            className={classnames(
                'rounded bg-blue-50 p-[10px] text-blue-500 caret-blue-600 outline-none placeholder:text-blue-600',
                {
                    'border border-blue-600': withBorder,
                    'border-0': !withBorder,
                },
                className
            )}
            type={type || 'text'}
            {...props}
        />
    )
})
