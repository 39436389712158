import type { DrugOrderItem } from './../../../types'
import { indexOf } from 'lodash'
import type { MouseEvent } from 'react'
import type { NewOrderProps } from '../types'

type UseCartProps = Pick<NewOrderProps, 'cartListData' | 'setCartListData'>

export const useCart = ({ cartListData, setCartListData }: UseCartProps) => {
    const drugInCart = (_drugObj: DrugOrderItem | undefined) =>
        cartListData.some((item) => item.id === _drugObj?.id)
    const findSameDrug = (_drugObj: DrugOrderItem | undefined) =>
        cartListData.find((item) => item.id === _drugObj?.id)

    const handleAddDrug = (
        e: MouseEvent,
        drugObj: DrugOrderItem | undefined,
    ) => {
        e.preventDefault()

        if (drugInCart(drugObj)) {
            const drugIndex = indexOf(cartListData, findSameDrug(drugObj))
            const cartArray = [...cartListData]

            let drugAmount = Number(cartArray[drugIndex]?.amount)

            cartArray[drugIndex] = {
                ...cartArray[drugIndex],
                amount: (drugAmount += 1),
            }

            setCartListData(cartArray)
            return
        }

        if (drugObj) {
            setCartListData((prevState) => [
                ...prevState,
                { ...drugObj, amount: 1 },
            ])
        }
    }

    const handleReduceDrug = (
        e: MouseEvent,
        drugObj: DrugOrderItem | undefined,
    ) => {
        e.preventDefault()

        if (!drugInCart(drugObj)) return

        const cartArray = [...cartListData]
        const drugIndex = indexOf(cartListData, findSameDrug(drugObj))

        let drugAmount = Number(cartArray[drugIndex]?.amount)

        if (drugAmount > 0) {
            cartArray[drugIndex] = {
                ...cartArray[drugIndex],
                amount: (drugAmount -= 1),
            }
        }

        if (drugAmount <= 0) {
            const removedItemArr = cartArray.filter(
                (drug) => drug.id !== cartArray[drugIndex].id,
            )
            return setCartListData(removedItemArr)
        }

        setCartListData(cartArray)
    }

    return { handleAddDrug, handleReduceDrug, drugInCart, findSameDrug }
}
