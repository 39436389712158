import { DrugSearchProps } from '../types'
import { useSearch } from '../hooks'
import { FormHTMLAttributes, useRef } from 'react'
import classnames from 'classnames'
import { ReactComponent as SpinnerIcon } from '../assets/icons/loader.svg'
import { ReactComponent as CrossOutlineIcon } from '../assets/icons/cross-outline.svg'
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg'

export const DrugSearch = (
    props: DrugSearchProps & FormHTMLAttributes<HTMLFormElement>,
) => {
    const {
        setIsOpen,
        isOpen,
        setSearchList,
        withArea,
        className,
        ...elementProps
    } = props

    const searchRef = useRef<HTMLInputElement>(null)

    const { handleSearchValue, handleStopSearch, handleSubmit, isPending } =
        useSearch({
            setIsOpen,
            setSearchList,
            searchInput: searchRef,
        })

    return (
        <form
            onSubmit={handleSubmit}
            className={classnames(
                'rounded bg-blue-50 p-2 outline-blue-800',
                {
                    'search-form': withArea,
                },
                className,
            )}
            {...elementProps}
        >
            <div className='flex items-center bg-inherit pl-2'>
                <SearchIcon className='mr-4 inline-block h-6 w-6 bg-cover bg-center bg-no-repeat' />
                <input
                    ref={searchRef}
                    onFocus={() => setIsOpen(true)}
                    onClick={() => setIsOpen(true)}
                    className='flex-1 border-none bg-inherit p-0 pr-[23px] text-blue-800 caret-blue-600 outline-none placeholder:text-blue-400 focus:ring-0 active:ring-0'
                    type='text'
                    placeholder='zadejte název, formu…'
                    onChange={(e) => handleSearchValue(e)}
                />
                {isPending && searchRef.current?.value !== '' && (
                    <SpinnerIcon className='mr-2 inline-block h-[18px] w-[18px] animate-[spin_1.5s_infinite] bg-cover bg-center bg-no-repeat' />
                )}
                {isOpen && (
                    <CrossOutlineIcon
                        className='inline-block h-[18px] w-[18px] bg-cover bg-center bg-no-repeat hover:cursor-pointer'
                        onClick={handleStopSearch}
                        type='button'
                    />
                )}
            </div>
        </form>
    )
}
