import { Outlet } from 'react-router-dom'
import { Footer } from '../components'

export const WithoutNavigation = () => {
    return (
        <>
            <div className='content-login__bg'></div>
            <div className='container'>
                <div className='content-login__main flex justify-center'>
                    <Outlet />
                </div>
                <Footer />
            </div>
        </>
    )
}
