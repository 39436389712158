import { DrugTable } from '../../../components'
import type { NewOrderProps } from '../types'
import { useCart } from '../hooks'
import { TableOverflowContainer } from '../../../containers'

export const NewOrderTable = ({
    listData,
    cartListData,
    setCartListData,
    inOpenContent,
}: NewOrderProps) => {
    const { handleAddDrug, handleReduceDrug, drugInCart, findSameDrug } =
        useCart({
            cartListData,
            setCartListData,
        })

    return (
        <TableOverflowContainer
            negativeMargin={false}
            className={`${inOpenContent && 'mb-8 -mr-[32px]'} max-h-[50vh]`}
        >
            <DrugTable
                negativeMargin={false}
                className={inOpenContent ? 'pr-[32px]' : 'pr-5'}
                drugList={listData}
                cartListData={cartListData}
                handleAdd={(e, drugObj) => handleAddDrug(e, drugObj)}
                handleReduce={(e, drugObj) => handleReduceDrug(e, drugObj)}
                handleDrugAmount={(drugObj) =>
                    drugInCart(drugObj)
                        ? findSameDrug(drugObj)?.amount || drugObj.amount
                        : 0
                }
            />
        </TableOverflowContainer>
    )
}
