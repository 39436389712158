import type { PropsWithChildren } from 'react'
import { useAuth } from '../../hooks'
import { AuthContextProvider } from './AuthContextProvider'

export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
    const { contextValue } = useAuth()

    return (
        <AuthContextProvider contextValue={contextValue}>
            {children}
        </AuthContextProvider>
    )
}
