import { HTMLAttributes } from 'react'
import { TableOverflowContainer } from '../containers'
import { useShowProcessed } from '../hooks'
import { Spinner } from './Spinner'
import { ProcessesTable } from './Table'

interface Config {
    showFinished?: boolean
}

type Props = Config & HTMLAttributes<HTMLDivElement>

export const ProcessedOrders = ({
    showFinished = false,
    ...elementProps
}: Props) => {
    const { showOrders, processedOrders } = useShowProcessed()

    return (
        <TableOverflowContainer {...elementProps}>
            {showOrders && processedOrders ? (
                <ProcessesTable
                    isFinished={showFinished}
                    negativeMargin={false}
                    orderData={processedOrders}
                    className='mb-6 pr-[88px]'
                />
            ) : (
                <Spinner />
            )}
        </TableOverflowContainer>
    )
}
