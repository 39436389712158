import axios from 'axios'
import type { Token, TokenRequestBody, ApiUser } from './types'
import { getRestApiConfig } from '../config'
import { AxiosErrorResult } from '../errors'

export const getMe = async (token: string): Promise<ApiUser> => {
    const { baseUrl, apiConfig } = getRestApiConfig(token)
    const requestUrl = new URL('api/v1/me', baseUrl).href
    const response = await axios.get<ApiUser>(requestUrl, apiConfig)
    return response.data
}

export const getToken = async ({
    UserName: email,
    password,
}: TokenRequestBody): Promise<Token> => {
    try {
        const { baseUrl } = getRestApiConfig()
        const requestUrl = new URL('api/v1/token', baseUrl).href
        const requestBody = {
            UserName: email,
            password,
        }

        const tokenApiConfig = {
            headers: {
                'Content-Type': 'application/json',
            },
            data: {},
        }

        const response = await axios.post<Token>(
            requestUrl,
            requestBody,
            tokenApiConfig,
        )
        return response.data
    } catch (e: any) {
        throw AxiosErrorResult(e)
    }
}
