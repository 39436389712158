import avatarIcon from '../assets/icons/avatar.svg'
import chevronDown from '../assets/icons/chevron-down.svg'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useNavigate } from 'react-router-dom'
import { useAuth, useOpen } from '../hooks'
import { useContext } from 'react'
import { AuthContext } from '../context'

export const UserOptions = () => {
    const {
        isOpen: optionsOpen,
        setIsOpen: setOptionsOpen,
        handleSwitch,
    } = useOpen()

    const ref = useDetectClickOutside({
        onTriggered: () => setOptionsOpen(false),
    })

    const { user } = useContext(AuthContext)
    const { handleSignOut } = useAuth()

    const navigate = useNavigate()

    return (
        <div
            ref={ref}
            className='relative flex flex-col'
            onClick={handleSwitch}
        >
            <div className='flex cursor-pointer items-center gap-2'>
                <span className='text-xl text-blue-800'>{user?.fullName}</span>
                <span className='flex h-[38px] w-[38px] items-center justify-center rounded-full bg-blue-50'>
                    <img
                        src={avatarIcon}
                        alt='user avatar icon'
                    />
                </span>
                <span
                    style={{ backgroundImage: `url('${chevronDown}')` }}
                    className='h-6 w-6 bg-cover bg-center bg-no-repeat'
                ></span>
            </div>
            {optionsOpen ? (
                <div className='absolute -bottom-[4.3rem] flex w-full flex-col items-center justify-center gap-1 rounded border border-blue-50 bg-white p-1'>
                    <button
                        onClick={() => navigate('/account')}
                        className='w-full pt-1 leading-normal'
                    >
                        Nastavení
                    </button>
                    <span className='my-1 h-px w-1/2 bg-blue-50'></span>
                    <button
                        onClick={() => handleSignOut()}
                        className='w-full pb-1 leading-normal'
                    >
                        Odhlásit se
                    </button>
                </div>
            ) : null}
        </div>
    )
}
