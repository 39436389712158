import moment from 'moment-timezone'

export const ensureDate = (date: string): boolean => {
    return moment(date, true).isValid()
}

export const formatTableDate = (date: string) => {
    date = moment(date).add(2, 'hours').tz('Europe/Prague').format()
    const isSameDay = moment(date).isSame(moment(), 'day')
    const isPreviousDay = moment(date).isSame(
        moment().subtract(1, 'day'),
        'day',
    )

    if (isSameDay) {
        return `Dnes - ${moment(date).format('HH:mm')}`
    }

    if (isPreviousDay) {
        return `Včera - ${moment(date).format('HH:mm')}`
    }

    return moment(date).format('DD.MM. - HH:mm')
}
