import classnames from 'classnames'
import type { HTMLAttributes } from 'react'
import { Portal } from 'react-portal'
import type { ModalStateProps } from '../types'
import { ReactComponent as CrossIcon } from '../assets/icons/cross.svg'

type ModalProps = HTMLAttributes<HTMLDivElement> & ModalStateProps

export const Modal = (props: ModalProps) => {
    const {
        children,
        onClick,
        className,
        isModalOpen,
        setIsModalOpen,
        handleClose,
        ...elementProps
    } = props

    return isModalOpen ? (
        <Portal>
            <div className='absolute inset-0 z-20 flex items-center justify-center bg-blue-950/50'>
                <div
                    className={classnames(
                        'relative rounded border-2 border-blue-600 bg-white',
                        className,
                    )}
                    {...elementProps}
                >
                    <CrossIcon
                        onClick={
                            handleClose
                                ? handleClose
                                : () => setIsModalOpen(false)
                        }
                        className='absolute top-5 right-5 h-6 w-6 cursor-pointer bg-cover bg-center bg-no-repeat'
                    ></CrossIcon>
                    {children}
                </div>
            </div>
        </Portal>
    ) : null
}
