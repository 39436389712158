export const Footer = () => {
    return (
        <div className='flex items-center gap-4 text-blue-500'>
            <span className='h-px w-[72px] bg-blue-200'></span>
            <span>
                vytvořil{' '}
                <a
                    href='https://www.nexum.cz/'
                    target='_blank'
                    rel='noreferrer'
                >
                    Nexum.cz
                </a>{' '}
                pro
                <a
                    className='mx-[.5ch] text-red-500 hover:underline'
                    href='https://velkoobchod.medispot.cz/home'
                    target='_blank'
                    rel='noreferrer'
                >
                    Medispot
                </a>
                &copy; 2022
            </span>
        </div>
    )
}
