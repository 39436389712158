import { useContext } from 'react'
import { Button, Spinner } from '../../components'
import { AuthContext } from '../../context'
import { useAuth, usePageTitle } from '../../hooks'
import { PageProps } from '../../types'
import { AccountLabel } from './components'

export const Account = ({ pageTitle }: PageProps) => {
    usePageTitle({ pageTitle })
    const { user } = useContext(AuthContext)
    const { handleSignOut } = useAuth()

    return user ? (
        <div className='grid max-w-[524px] gap-6'>
            <div className='grid gap-3'>
                <AccountLabel
                    labelText='Celé jméno'
                    value={user.fullName}
                />
                <AccountLabel
                    labelText='Kontaktní email'
                    value={user.email}
                    type='email'
                />
                <AccountLabel
                    labelText='Role'
                    value={user.roles[0]}
                />
                <AccountLabel
                    labelText='Organizace'
                    value={user.organization}
                />
            </div>
            <div className='text-right'>
                <Button
                    buttontype='secondary'
                    onClick={() => handleSignOut()}
                >
                    odhlásit se
                </Button>
            </div>
        </div>
    ) : (
        <Spinner />
    )
}
