import { Heading, ProcessedOrders } from '../../components'
import { usePageTitle } from '../../hooks'
import { PageProps } from '../../types'

export const AllOrders = ({ pageTitle }: PageProps) => {
    usePageTitle({ pageTitle })
    return (
        <div>
            <Heading
                headingType='main'
                className='mb-6'
            >
                Všechny objednávky
            </Heading>
            <Heading
                headingType='secondary'
                className='mb-3'
            >
                Zpracovávané objednávky
            </Heading>
            <ProcessedOrders className='mb-6 max-h-[28vh]' />
            <div className='mb-4 mr-[88px] flex items-center justify-between'>
                <Heading headingType='secondary'>Dokončené objednávky</Heading>
                {/* Add button when export all will be implemented from BE */}
                {/* <Button
                    size='small'
                    buttontype='secondary'
                >
                    exportovat vše
                </Button> */}
            </div>
            <ProcessedOrders
                className='max-h-[28vh]'
                showFinished
            />
        </div>
    )
}
