import classnames from 'classnames'
import { Input } from '../../../components'
import type { AccountLabelProps } from '../types'

export const AccountLabel = (props: AccountLabelProps) => {
    const {
        className,
        value,
        type = 'text',
        labelText,
        ...elementProps
    } = props

    return (
        <label
            className={classnames('grid gap-2', className)}
            {...elementProps}
        >
            <span>{labelText}</span>
            <Input
                withBorder={false}
                value={value}
                type={type}
                readOnly
            />
        </label>
    )
}
