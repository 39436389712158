import { Button } from '../../../components'
import { OrderActionModal } from './OrderActionModal'
import type { ActionModalProps } from '../types'
import { MouseEvent, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOrder } from '../hooks'
import type { NewOrderPageProps, OrderState } from '../../../types'
import { AuthContext } from '../../../context'
import { useParams } from 'react-router-dom'
import { getOrderDetailList } from '../../../api'

type ButtonClick = (e: MouseEvent) => void

interface OrderActionsConfig {
    cancelButtonClick?: ButtonClick
    sendButtonClick?: ButtonClick
    showMainButtons?: boolean
    orderNote: string
}

type OrderActionsProps = ActionModalProps &
    OrderActionsConfig &
    NewOrderPageProps

export const OrderActions = (props: OrderActionsProps) => {
    const {
        showMainButtons = true,
        cancelState,
        sendState,
        cancelButtonClick,
        sendButtonClick,
        cartListData,
        isUrgent,
        orderNote,
    } = props

    const navigate = useNavigate()
    const { user } = useContext(AuthContext)
    const { id, duplicatedId } = useParams()
    const hasAuthority = user?.roles[0] === 'schvalovatel'
    const [orderState, setOrderState] = useState<string | undefined>()
    const disabledSaveStates = ['vracenoLekarnou', 'predanoKeKontrole']
    const hasData = !!id || !!duplicatedId

    const handleSendOrder = useOrder({ cartListData, isUrgent })

    const handleCancelOrderClick = (e: MouseEvent, isAlreadyLive: boolean) => {
        if (isAlreadyLive) {
            return handleSendOrder(e, 'zruseno', false, false, orderNote)
        }
        return navigate('/home')
    }

    useEffect(() => {
        if (!!hasData) {
            const getOrderData = async () => {
                await getOrderDetailList(String(id)).then(({ state }) =>
                    setOrderState(state),
                )
            }
            getOrderData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {showMainButtons && (
                <>
                    <Button
                        buttontype='secondary'
                        onClick={cancelButtonClick}
                    >
                        ukončit objednávku
                    </Button>
                    <Button
                        disabled={!cartListData.length}
                        buttontype='primary'
                        onClick={sendButtonClick}
                    >
                        zadat objednávku
                    </Button>
                </>
            )}
            <OrderActionModal
                modalheading={
                    !!id
                        ? 'Přejete si zrušit objednávku?'
                        : 'Přejete si nedokončenou objednávku uložit?'
                }
                subheading={
                    !!id
                        ? 'V případě zrušení objednávky nebude nadále možné ji editovat.'
                        : undefined
                }
                isModalOpen={cancelState.isModalOpen}
                setIsModalOpen={cancelState.setIsModalOpen}
            >
                <Button
                    buttontype='secondary'
                    onClick={(e) => handleCancelOrderClick(e, !!id)}
                >
                    {!!id ? 'zrušit objednávku' : 'smazat objednávku'}
                </Button>
                <Button
                    disabled={!cartListData.length}
                    onClick={(e) =>
                        handleSendOrder(
                            e,
                            !!id ? undefined : 'nedokonceno',
                            false,
                            false,
                            orderNote,
                        )
                    }
                >
                    uložit na později
                </Button>
            </OrderActionModal>
            <OrderActionModal
                modalheading='Přejete si zadat objednávku?'
                subheading={
                    hasAuthority
                        ? 'Objednávka bude zaslána na lékárnu a dále nebude možné ji editovat. Přejete si odeslat objednávku na lékárnu?'
                        : 'Objednávka bude předávána ke kontrole a dále nebude možné ji editovat. Přejete si odeslat objednávku ke kontrole?'
                }
                isModalOpen={sendState.isModalOpen}
                setIsModalOpen={sendState.setIsModalOpen}
            >
                {((orderState && !disabledSaveStates.includes(orderState)) ||
                    !hasData) && (
                    <Button
                        disabled={!cartListData.length}
                        onClick={(e) =>
                            handleSendOrder(
                                e,
                                'predanoKeKontrole',
                                false,
                                false,
                                orderNote,
                            )
                        }
                        buttontype='secondary'
                    >
                        Předat ke kontrole
                    </Button>
                )}
                {hasAuthority && (
                    <Button
                        disabled={!cartListData.length}
                        onClick={(e) =>
                            handleSendOrder(
                                e,
                                'predanoNaLekarnu',
                                false,
                                false,
                                orderNote,
                            )
                        }
                    >
                        Zadat objednávku
                    </Button>
                )}
            </OrderActionModal>
        </>
    )
}
