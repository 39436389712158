import { HTMLAttributes } from 'react'
import { useUIDSeed } from 'react-uid'
import { DrugObjType, DrugTableConfig } from '../../types'
import { DrugTableCell } from './DrugTableCell'

type DrugRowProps = Omit<DrugTableConfig<DrugObjType>, 'drugList'> &
    HTMLAttributes<HTMLTableRowElement>

export const DrugTableRow = (props: DrugRowProps) => {
    const {
        handleAdd,
        handleReduce,
        hideHeaders,
        handleDrugAmount,
        drugObj,
        disableRow,
        className,
        cartListData,
        isUrgent,
        ...elementProps
    } = props
    const uid = useUIDSeed()

    return drugObj ? (
        <tr
            className={`truncate-auto odd:bg-blue-100 ${
                drugObj && disableRow && disableRow(drugObj)
                    ? '!bg-gray-100'
                    : ''
            } ${className}`}
            key={uid(drugObj)}
            {...elementProps}
        >
            {hideHeaders
                ? []
                : [
                      drugObj.id,
                      drugObj.title,
                      drugObj.form,
                      drugObj.unit,
                      (drugObj.amount = handleDrugAmount
                          ? handleDrugAmount(drugObj)
                          : 0),
                  ].map((item) => (
                      <DrugTableCell
                          key={uid(`${drugObj}-${item}`)}
                          item={item}
                          drugObj={drugObj}
                          handleAdd={handleAdd}
                          handleReduce={handleReduce}
                          cartListData={cartListData}
                          isUrgent={isUrgent}
                          disableButtons={
                              disableRow ? disableRow(drugObj) : false
                          }
                      />
                  ))}
        </tr>
    ) : null
}
