import classnames from 'classnames'
import { TableHTMLAttributes, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUIDSeed } from 'react-uid'
import { getExportLink } from '../../api/orders/services'
import { AuthContext } from '../../context'
import { setInteractivity } from '../../lib'
import { OrderState, ProcessedOrder } from '../../types'
import { FinishedProcessCell, ProcessedCell } from './ProcessedCell'

interface ProcessesRowConfig {
    order: ProcessedOrder
}

type ProcessesProps = ProcessesRowConfig &
    TableHTMLAttributes<HTMLTableRowElement>

export const ProcessedRow = ({ order, ...elementProps }: ProcessesProps) => {
    const uid = useUIDSeed()
    const { user } = useContext(AuthContext)
    const userRole = user?.roles[0]

    return userRole && order.state ? (
        <tr
            {...elementProps}
            key={uid(order)}
            className={classnames(
                'truncate-auto',
                setInteractivity(order.state, userRole),
            )}
        >
            {[
                order.numberOrder,
                OrderState[order.state],
                order.companyName,
                order.lastModifyDate,
            ].map((item) => (
                <ProcessedCell
                    order={order}
                    item={item}
                />
            ))}
        </tr>
    ) : null
}

export const FinishedRow = ({ order, ...elementProps }: ProcessesProps) => {
    const uid = useUIDSeed()
    const navigate = useNavigate()
    const { user } = useContext(AuthContext)
    const isPharmacy = user?.roles[0] === 'kompletace'

    const handleExport = async (numberOrder: string) => {
        try {
            const exportUrl = await getExportLink({ numberOrder })
            window.open(URL.createObjectURL(exportUrl))
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <tr
            {...elementProps}
            className={classnames('truncate-auto', {
                'bg-blue-100': order.isUrgent,
            })}
            key={uid(order)}
        >
            {[order.numberOrder, order.lastModifyDate, order.companyName].map(
                (item) => (
                    <FinishedProcessCell
                        order={order}
                        item={item}
                    />
                ),
            )}
            <td className='align-top'>
                <span
                    className='cursor-pointer hover:underline'
                    onClick={() => handleExport(order.numberOrder)}
                >
                    export
                </span>
                {!isPharmacy && (
                    <>
                        <span className='px-[.5ch]'>|</span>
                        <span
                            onClick={() =>
                                navigate(
                                    `/new-order/duplicate/${order.numberOrder}`,
                                )
                            }
                            className='cursor-pointer hover:underline'
                        >
                            duplikace
                        </span>
                    </>
                )}
            </td>
        </tr>
    )
}
