import { useContext } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useUIDSeed } from 'react-uid'
import { ProtectedRoute } from '../components/ProtectedRoute'
import { WithNavigation, WithoutNavigation } from '../containers'
import { AuthContext } from '../context'
import { Account, AllOrders, Home, Login, NewOrder } from '../pages'
import { OrderDetail } from '../pages/OrderDetail'
import { ProtectedRouteProps } from '../types'

export const AppRouter = () => {
    const uid = useUIDSeed()
    const { isAuthenticated } = useContext(AuthContext)

    const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
        isAuthenticated,
        authenticationPath: '/login',
    }

    return (
        <Router>
            <Routes>
                <Route element={<WithoutNavigation />}>
                    {['/', '/login'].map((path) => (
                        <Route
                            key={uid(path)}
                            path={path}
                            element={<Login pageTitle='Přihlášení' />}
                        />
                    ))}
                </Route>
                <Route
                    element={
                        <ProtectedRoute
                            {...defaultProtectedRouteProps}
                            outlet={<WithNavigation />}
                        />
                    }
                >
                    <Route
                        path='/home'
                        element={<Home pageTitle='Domovská stránka' />}
                    />
                    <Route
                        path='/all-orders'
                        element={<AllOrders pageTitle='Všechny objednávky' />}
                    />
                    <Route
                        path='/account'
                        element={<Account pageTitle='Účet' />}
                    />
                </Route>
                <Route
                    element={
                        <ProtectedRoute
                            {...defaultProtectedRouteProps}
                            outlet={<WithNavigation isWider />}
                        />
                    }
                >
                    <Route
                        path='/order-detail/:id'
                        element={<OrderDetail pageTitle='Detail objednávky' />}
                    />
                    <Route path='/new-order'>
                        <Route
                            path='duplicate/:duplicatedId'
                            element={
                                <NewOrder pageTitle='Nová objednávka | Duplicitní' />
                            }
                        />
                        <Route
                            path=':id'
                            element={<NewOrder pageTitle='Objednávka' />}
                        />
                        <Route
                            path=''
                            element={<NewOrder pageTitle='Nová objednávka' />}
                        />
                    </Route>
                    <Route
                        path='/new-order-urgent'
                        element={
                            <NewOrder
                                pageTitle='Nová objednávka | Urgentní'
                                isUrgent
                            />
                        }
                    />
                </Route>
            </Routes>
        </Router>
    )
}
