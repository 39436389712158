import shoppingCartIcon from '../../../assets/icons/shopping-cart.svg'
import { Modal } from '../../../components'
import { useOpen } from '../../../hooks'
import { NewOrderTable } from './NewOrderTable'
import type { MouseEvent } from 'react'
import type { CartWrapperProps, CartModalProps } from '../types'
import { OrderActions } from './OrderActions'
import { useFormContext } from 'react-hook-form'

type FunctionAction = (e: MouseEvent) => void

const CartModal = (props: CartModalProps) => {
    const { getValues } = useFormContext()

    const {
        isModalOpen: isCartOpen,
        setIsModalOpen: setCartOpen,
        cancelState,
        sendState,
        listData,
        cartListData,
        setCartListData,
        isUrgent,
    } = props

    const handleActionFunction = (
        e: MouseEvent,
        functionAction: FunctionAction,
    ) => {
        e.preventDefault()
        setCartOpen(false)
        functionAction(e)
    }

    const handleCancelOrder = () => cancelState.setIsModalOpen(true)
    const handleSendOrder = () => sendState.setIsModalOpen(true)

    return (
        <Modal
            isModalOpen={isCartOpen}
            setIsModalOpen={setCartOpen}
            className={`grid ${listData.length ? 'w-[906px]' : ''} gap-5 p-8`}
        >
            <span className='text-center text-xl leading-normal text-blue-600'>
                Váš košík
            </span>
            {listData.length ? (
                <div className='pl-5'>
                    <NewOrderTable
                        cartListData={cartListData}
                        setCartListData={setCartListData}
                        listData={listData}
                    />
                </div>
            ) : (
                'Váš košík je prázdný.'
            )}
            <div className='flex justify-end gap-4 pr-5'>
                <OrderActions
                    orderNote={getValues('order-note')}
                    isUrgent={isUrgent}
                    cartListData={cartListData}
                    setCartListData={setCartListData}
                    listData={listData}
                    cancelState={cancelState}
                    sendState={sendState}
                    cancelButtonClick={(e) =>
                        handleActionFunction(e, handleCancelOrder)
                    }
                    sendButtonClick={(e) =>
                        handleActionFunction(e, handleSendOrder)
                    }
                />
            </div>
        </Modal>
    )
}

export const CartWrapper = (props: CartWrapperProps) => {
    const {
        cancelState,
        sendState,
        cartListData,
        listData,
        setCartListData,
        isUrgent,
    } = props
    const { isOpen, setIsOpen } = useOpen()

    return (
        <>
            <CartModal
                isUrgent={isUrgent}
                cartListData={cartListData}
                listData={listData}
                cancelState={cancelState}
                sendState={sendState}
                isModalOpen={isOpen}
                setIsModalOpen={setIsOpen}
                setCartListData={setCartListData}
            />
            <button
                onClick={(e: MouseEvent) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setIsOpen(true)
                }}
                className='cart-button flex items-center justify-between rounded bg-blue-200/60 py-2 px-4 text-blue-800 transition-colors hover:bg-blue-100'
            >
                zobrazit košík
                <div className='relative'>
                    <img
                        src={shoppingCartIcon}
                        alt=''
                    />
                    {!!listData.length && (
                        <span className='absolute -right-2 -top-1 flex h-5 w-5 items-center justify-center rounded-full bg-blue-50 text-sm'>
                            {listData.length}
                        </span>
                    )}
                </div>
            </button>
        </>
    )
}
