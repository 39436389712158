import { useNavigate } from 'react-router-dom'
import { useUIDSeed } from 'react-uid'
import { ensureDate, formatTableDate } from '../../lib'
import { ProcessedOrder } from '../../types'
import { ReactComponent as UrgentIcon } from '../../assets/icons/urgent.svg'
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg'
import classnames from 'classnames'
import { useContext } from 'react'
import { AuthContext } from '../../context'
import { handleAccess } from '../../utils'

interface Config {
    order: ProcessedOrder
    item: string
}

export const ProcessedCell = ({ order, item }: Config) => {
    const uid = useUIDSeed()
    const navigate = useNavigate()
    const { user } = useContext(AuthContext)

    const handleNavigation = () => {
        if (user) {
            const accessRoute = handleAccess(order.state, user?.roles[0])
                ? '/new-order/'
                : '/order-detail/'
            return navigate(accessRoute + order.numberOrder)
        }
    }

    return (
        <td
            key={uid(`${order}${item}`)}
            className={classnames('align-top hover:cursor-pointer', {
                'min-w-[157px] max-w-[160px] uppercase':
                    item === order.companyName,
            })}
            onClick={handleNavigation}
        >
            {item !== order.numberOrder && ensureDate(item)
                ? formatTableDate(item)
                : item}
        </td>
    )
}

export const FinishedProcessCell = ({ order, item }: Config) => {
    const uid = useUIDSeed()
    const navigate = useNavigate()
    const hasIcon = order.isUrgent || order.state === 'zruseno'

    return (
        <td
            key={uid(`${order}${item}`)}
            onClick={() => navigate(`/order-detail/${order.numberOrder}`)}
            className={classnames('align-top hover:cursor-pointer', {
                flex: item === order.numberOrder && hasIcon,
                'min-w-[157px] max-w-[160px] uppercase':
                    item === order.companyName,
            })}
        >
            {item !== order.numberOrder && ensureDate(item)
                ? formatTableDate(item)
                : item}
            {item === order.numberOrder && !!order.isUrgent && (
                <UrgentIcon className='ml-2 h-6 w-6 bg-cover bg-center bg-no-repeat' />
            )}
            {item === order.numberOrder && order.state === 'zruseno' && (
                <CrossIcon className='ml-1 h-6 w-6 bg-cover bg-center bg-no-repeat [&>path]:stroke-red-600' />
            )}
        </td>
    )
}
