export const getAuthorizationHeader = (token?: string | null): string => {
    token = token || getToken()
    if (!token) return ''
    return `Bearer ${token}`
}

export const getToken = (): string | null => {
    return localStorage.getItem('token')
}

const getConfigHeaders = (token?: string, isPDF = false) => ({
    'Content-Type': isPDF ? 'application/pdf' : 'application/json',
    Authorization: getAuthorizationHeader(token),
})

export const getRestApiConfig = (token?: string, isPDF = false) => {
    return {
        baseUrl: process.env.REACT_APP_API_HOST,
        apiConfig: {
            headers: getConfigHeaders(token, isPDF),
            data: {},
        },
    }
}
