import { OrderTable } from './OrderTable'
import type { ProcessesTableProps } from '../../types'
import { FinishedRow, ProcessedRow } from './ProcessRow'

export const ProcessesTable = (props: ProcessesTableProps) => {
    let { orderData } = props

    const {
        className,
        negativeMargin,
        isFinished = false,
        ...elementProps
    } = props
    // const { user } = useContext(AuthContext)
    // const isHospitalUser = user?.roles[0] !== 'kompletace'
    const finishedHeaders = [
        'č. objednávky',
        'dat. vytvoření',
        'organizace',
        'možnosti',
    ]

    const processedHeaders = [
        'č. objednávky',
        'stav',
        'organizace',
        'dat. poslední změny',
    ]

    orderData = orderData?.filter((order) =>
        isFinished
            ? order.state === 'dokonceno' || order.state === 'zruseno'
            : order.state !== 'dokonceno' && order.state !== 'zruseno',
    )

    return orderData?.length !== 0 ? (
        <OrderTable
            headers={isFinished ? finishedHeaders : processedHeaders}
            negativeMargin={negativeMargin}
            className={className}
            {...elementProps}
        >
            {orderData?.map((order) =>
                isFinished ? (
                    <FinishedRow order={order} />
                ) : (
                    <ProcessedRow order={order} />
                ),
            )}
        </OrderTable>
    ) : (
        <p className='pl-2'>
            {isFinished
                ? 'Momentálně nemáte žádné dokončené objednávky.'
                : 'Momentálně jsou všechny Vaše objednávky zpracovány.'}
        </p>
    )
}
