import { ProcessedOrders } from '../../components'
import { usePageTitle } from '../../hooks'
import { PageProps } from '../../types'
import { ActionCards } from './containers'

export const Home = ({ pageTitle }: PageProps) => {
    usePageTitle({ pageTitle })

    return (
        <main className='home-content'>
            <ActionCards />
            <div className='home-orders'>
                <h2 className='mb-3 text-2xl leading-normal text-blue-800'>
                    Zpracovávané objednávky
                </h2>
            </div>
            <ProcessedOrders className='max-h-[50vh]' />
        </main>
    )
}
