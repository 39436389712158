import classnames from 'classnames'
import { Dispatch, HTMLAttributes, SetStateAction } from 'react'
import { useUIDSeed } from 'react-uid'
import { DrugTableCell } from '../../../components/Table/DrugTableCell'
import { useOpen } from '../../../hooks'
import { DetailOrderItem } from '../../../types'
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg'
import { ReactComponent as StateCrossIcon } from '../../../assets/icons/cross.svg'
import { Button } from '../../../components'
import { replaceDrugInOrder } from '../../../api'
import { useParams } from 'react-router-dom'

interface RowConfig {
    order: DetailOrderItem
    orderData: DetailOrderItem[]
    disableClick?: boolean
    setNewDrugSent?: Dispatch<SetStateAction<boolean>>
    orderNote: string
    isUrgent?: boolean
    handleDrugToReplace?: () => void
}

type RowProps = RowConfig & HTMLAttributes<HTMLTableRowElement>

export const OrderDetailRow = ({
    order,
    className,
    disableClick,
    orderData,
    handleDrugToReplace,
    orderNote,
    isUrgent,
    ...elementProps
}: RowProps) => {
    const uid = useUIDSeed()

    return (
        <tr
            className={classnames(
                'truncate-auto relative odd:bg-blue-100',
                {
                    'cursor-pointer': disableClick,
                },
                className,
            )}
            key={uid(order)}
            {...elementProps}
        >
            {[order.id, order.title, order.form, order.unit, order.amount].map(
                (item) =>
                    item !== order.amount ? (
                        <DrugTableCell
                            onClick={handleDrugToReplace}
                            isDetail
                            cartListData={orderData}
                            drugObj={order}
                            item={item}
                            isUrgent={isUrgent}
                        />
                    ) : (
                        <DrugTableCell
                            isDetail
                            cartListData={orderData}
                            drugObj={order}
                            item={item}
                            isUrgent={isUrgent}
                        />
                    ),
            )}
        </tr>
    )
}

export const OrderReplacementRow = ({
    order,
    setNewDrugSent,
    orderData,
    disableClick,
    orderNote,
    isUrgent,
}: RowProps) => {
    const { isOpen, handleSwitch } = useOpen()
    const { id: orderNumber } = useParams()

    const resetChanges = async () => {
        const items = [
            {
                drugID: order.id,
                amount: 0,
                note: order.note || '',
                state: order.state || 'ok',
                replacementForDrugID: order.replacementFor?.id,
            },
        ]
        if (orderNumber) {
            await replaceDrugInOrder({
                orderNumber,
                items,
            }).then(() => setNewDrugSent && setNewDrugSent(true))
        }
    }

    return (
        <>
            {order.replacementFor && (
                <OrderDetailRow
                    orderNote={orderNote}
                    orderData={orderData}
                    className={classnames({
                        hidden: !isOpen,
                        'table-row !bg-green-100': isOpen,
                    })}
                    order={isOpen ? order : order.replacementFor}
                    onClick={handleSwitch}
                    isUrgent={isUrgent}
                />
            )}
            <tr className={isOpen ? 'table-row' : 'hidden'}>
                <td
                    colSpan={5}
                    align='center'
                    className='border-none'
                >
                    <div className='grid grid-cols-3 items-center'>
                        <div></div>
                        <ChevronDownIcon className='mx-auto inline-block h-[24px] w-[24px] rotate-180' />
                        {disableClick ? (
                            <Button
                                className='ml-auto w-fit'
                                onClick={resetChanges}
                            >
                                vrátit změny
                            </Button>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </td>
            </tr>
            {order.replacementFor && (
                <OrderDetailRow
                    orderNote={orderNote}
                    orderData={orderData}
                    className={classnames('cursor-pointer ', {
                        '!bg-red-100': isOpen,
                        '!bg-green-100': !isOpen,
                    })}
                    onClick={handleSwitch}
                    isUrgent={isUrgent}
                    order={isOpen ? order.replacementFor : order}
                />
            )}
        </>
    )
}
