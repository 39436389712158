import { replaceDrugInOrder } from '../../../api'
import type { DrugOrderItem } from '../../../types'
import { Dispatch, MouseEvent, SetStateAction } from 'react'

export const useDrugReplace = (
    newDrug: DrugOrderItem | undefined,
    setNewDrug: Dispatch<SetStateAction<DrugOrderItem | undefined>>,
) => {
    const handleAddDrug = (e: MouseEvent, drugObj?: DrugOrderItem) => {
        if (!!newDrug) {
            if (newDrug?.id !== drugObj?.id) return

            let amount = Number(newDrug.amount)
            setNewDrug({ ...newDrug, amount: amount + 1 })
            return
        }
        drugObj && setNewDrug({ ...drugObj, amount: 1 })
    }

    const handleReduceDrug = (e: MouseEvent, drugObj?: DrugOrderItem) => {
        if (!!newDrug) {
            if (newDrug?.id !== drugObj?.id) return
            if (Number(newDrug.amount) <= 1) {
                setNewDrug(undefined)
                return
            }
            let amount = Number(newDrug.amount)
            setNewDrug({ ...newDrug, amount: amount - 1 })
            return
        }
    }

    const handleDrugReplace = async (
        e: MouseEvent,
        orderNumber: string | undefined,
        replaceDrug: DrugOrderItem,
        newDrug: DrugOrderItem,
        setNewDrug?: Dispatch<SetStateAction<DrugOrderItem | undefined>>,
        setNewDrugSent?: Dispatch<SetStateAction<boolean>>,
    ) => {
        e.preventDefault()
        if (!!newDrug) {
            const items = [
                {
                    drugID: newDrug.id,
                    amount: Number(newDrug.amount),
                    note: replaceDrug.note,
                    state: newDrug.state || 'ok',
                    replacementForDrugID: replaceDrug.id,
                },
            ]
            await replaceDrugInOrder({ orderNumber, items })
                .then(() => setNewDrugSent && setNewDrugSent(true))
                .then(() => setNewDrug && setNewDrug(undefined))
        }
    }

    return { handleDrugReplace, handleAddDrug, handleReduceDrug }
}
