export enum OrderState {
    predanoKeKontrole = 'předáno ke kontrole',
    nedokonceno = 'nedokončeno',
    prijatoNaLekarnu = 'přijato na lékárnu',
    predanoNaLekarnu = 'předáno na lékárnu',
    zruseno = 'zrušeno',
    dokonceno = 'dokončeno',
    vracenoLekarnou = 'vráceno lékárnou',
}

type DrugState = 'ok' | 'emptywarehous'
export interface ProcessedOrder {
    numberOrder: string
    companyName: string
    lastModifyDate: string
    state: keyof typeof OrderState
    isUrgent?: boolean
    note: string
}

export interface DetailOrderItem {
    title: string
    id: string
    form: string
    unit: string
    amount: number
    note: string
    state: DrugState
    replacementFor?: Omit<DetailOrderItem, 'replacementFor'>
}

export interface DrugItem {
    title: string
    id: string
    form: string
    unit: string
    note: string
    state: DrugState
}

export interface DrugOrderItem extends DrugItem {
    amount?: number
}

export interface ReplacementItem {
    drugID: string
    amount: number
    note: string
    state: DrugState
    replacementForDrugID?: string
}

export interface ReplaceDrugProps {
    orderNumber: string | undefined
    items: ReplacementItem[]
}
