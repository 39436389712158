import { useState } from 'react'

export const useOpen = (initialState?: boolean) => {
    const [isOpen, setIsOpen] = useState(initialState || false)

    const handleSwitch = () => {
        setIsOpen((prevState) => !prevState)
    }

    return { isOpen, setIsOpen, handleSwitch }
}
